@import url(https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap);
.App {
  text-align: center;
}


body {
  font-family: 'ubuntu', sans-serif;}

code {
  font-family: 'ubuntu', sans-serif;}


.pre {
  background-image: url("/pics/blurry-gradient-haikei.svg");
  /* background-repeat: no-repeat; */
  /* height: 100vh; */
  /* background-size: cover; */
}
.ant-layout-header {
  height: 64px;
  padding: 0 50px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 64px;
  background: #ffffff;
}

.ant-layout-content {
background: rgb(24,144,255);
background: linear-gradient(90deg, rgba(24,144,255,1) 0%, rgba(24,144,255,1) 27%, rgba(0,212,255,1) 100%);
}

@media screen and (max-width: 900px){
.ant-card {
margin-left: 2%!important;
margin-right: 2%!important;
}}

.ant-card {
  margin-left:15%;
  margin-right:15%;
  position:relative;
  padding: 75px 45px 75px 45px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 1rem;
  line-height: 1.5715;
  list-style: none;
  background: #fff;
  border-radius: 15px;
  /* box-shadow: 5px 8px 24px 5px rgba(208, 216, 243, 0.6) */
  /* box-shadow: 5px 8px 24px 5px rgba(0, 0, 0, 0.6) */
  /* box-shadow: 1px 1px 1px 1px rgba(0, 3, 5, 0.6) */
}

h1,h2,h3,h4,p,b {
margin-bottom:0!important; 
}

h2,h3 {
line-height:1.2em;
}

p {
font-size: 20px;
}
.water_box {
  border: 2px solid #1890ff ;
  padding: 4px 15px;
  border-radius: 5px;
  line-height: 4;
  color:#1890ff;

}

.wood_box {
  border: 2px solid rgb(169, 121, 77);
  padding: 4px 15px;
  border-radius: 5px;
  line-height: 4;
  color:rgb(169, 121, 77);

}

.boxed {
  border: 2px solid ;
  padding: 4px 15px 4px 15px;
  border-radius: 5px;
  line-height: 2;
  
}
.boxedd {
  border: 4px solid 
  /* #1890ff rgb(169, 121, 77) */
  ;
  padding: 4px 15px 4px 15px;
  border-radius: 5px;
  line-height: 2; 
  /* text-decoration: line-through #1890ff  ; */
  /* color:
  rgb(169, 121, 77); */
  /* #1890ff; */
}

  .boxeddd {
    border: 4px solid ;
    padding: 4px 15px;
    border-radius: 5px;
    line-height: 4; 

}
.ant-btn {
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 4px 15px;
  font-size: 14px;
  border-radius: 5px;
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  border-color: #d9d9d9;
}
.ant-btn > .anticon {
  line-height: 1;
}
.ant-btn,
.ant-btn:active,
.ant-btn:focus {
  outline: 0;
}
.ant-btn:not([disabled]):hover {
  text-decoration: none;
}
.ant-btn:not([disabled]):active {
  outline: 0;
  box-shadow: none;
}
.ant-btn[disabled] {
  cursor: not-allowed;
}
.ant-btn[disabled] > * {
  pointer-events: none;
}
.ant-btn-lg,
.ant-btn-sm {
  height: 40px;
  padding: 6.4px 15px;
  font-size: 16px;
  border-radius: 5px;
}
.ant-btn-sm {
  height: 24px;
  padding: 0 7px;
  font-size: 14px;
}
.ant-btn > a:only-child {
  color: currentColor;
}
.ant-btn > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn:focus,
.ant-btn:hover {
  color: #40a9ff;
  border-color: #40a9ff;
}
.ant-btn:focus > a:only-child,
.ant-btn:hover > a:only-child {
  color: currentColor;
}
.ant-btn:focus > a:only-child::after,
.ant-btn:hover > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn:active {
  color: #096dd9;
  background: #fff;
  border-color: #096dd9;
}
.ant-btn-primary > a:only-child,
.ant-btn:active > a:only-child {
  color: currentColor;
}
.ant-btn-primary > a:only-child::after,
.ant-btn:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn[disabled],
.ant-btn[disabled]:active,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:hover {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-primary:focus > a:only-child,
.ant-btn-primary:hover > a:only-child,
.ant-btn[disabled]:active > a:only-child,
.ant-btn[disabled]:focus > a:only-child,
.ant-btn[disabled]:hover > a:only-child,
.ant-btn[disabled] > a:only-child {
  color: currentColor;
}
.ant-btn-primary:focus > a:only-child::after,
.ant-btn-primary:hover > a:only-child::after,
.ant-btn[disabled]:active > a:only-child::after,
.ant-btn[disabled]:focus > a:only-child::after,
.ant-btn[disabled]:hover > a:only-child::after,
.ant-btn[disabled] > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn:active,
.ant-btn:focus,
.ant-btn:hover {
  text-decoration: none;
  background: #fff;
}
.ant-btn > span,
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
  display: inline-block;
}
.ant-btn-primary {
  color: #fff;
  background: #1890ff;
  border-color: #1890ff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-primary:focus,
.ant-btn-primary:hover {
  color: #fff;
  background: #40a9ff;
  border-color: #40a9ff;
}
.ant-btn-primary:active {
  color: #fff;
  background: #096dd9;
  border-color: #096dd9;
}
.ant-btn-ghost > a:only-child,
.ant-btn-primary:active > a:only-child {
  color: currentColor;
}
.ant-btn-ghost > a:only-child::after,
.ant-btn-primary:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:active,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:hover {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-ghost:focus > a:only-child,
.ant-btn-ghost:hover > a:only-child,
.ant-btn-primary[disabled]:active > a:only-child,
.ant-btn-primary[disabled]:focus > a:only-child,
.ant-btn-primary[disabled]:hover > a:only-child,
.ant-btn-primary[disabled] > a:only-child {
  color: currentColor;
}
.ant-btn-ghost:focus > a:only-child::after,
.ant-btn-ghost:hover > a:only-child::after,
.ant-btn-primary[disabled]:active > a:only-child::after,
.ant-btn-primary[disabled]:focus > a:only-child::after,
.ant-btn-primary[disabled]:hover > a:only-child::after,
.ant-btn-primary[disabled] > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child) {
  border-right-color: #40a9ff;
  border-left-color: #40a9ff;
}
.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child):disabled {
  border-color: #d9d9d9;
}
.ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
  border-right-color: #40a9ff;
}
.ant-btn-group .ant-btn-primary:first-child:not(:last-child)[disabled] {
  border-right-color: #d9d9d9;
}
.ant-btn-group .ant-btn-primary + .ant-btn-primary,
.ant-btn-group .ant-btn-primary:last-child:not(:first-child) {
  border-left-color: #40a9ff;
}
.ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled],
.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled] {
  border-left-color: #d9d9d9;
}
.ant-btn-ghost {
  color: rgba(0, 0, 0, 0.85);
  background: 0 0;
  border-color: #d9d9d9;
}
.ant-btn-ghost:focus,
.ant-btn-ghost:hover {
  color: #40a9ff;
  background: 0 0;
  border-color: #40a9ff;
}
.ant-btn-ghost:active {
  color: #096dd9;
  background: 0 0;
  border-color: #096dd9;
}
.ant-btn-dashed > a:only-child,
.ant-btn-ghost:active > a:only-child {
  color: currentColor;
}
.ant-btn-dashed > a:only-child::after,
.ant-btn-ghost:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn-ghost[disabled],
.ant-btn-ghost[disabled]:active,
.ant-btn-ghost[disabled]:focus,
.ant-btn-ghost[disabled]:hover {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dashed:focus > a:only-child,
.ant-btn-dashed:hover > a:only-child,
.ant-btn-ghost[disabled]:active > a:only-child,
.ant-btn-ghost[disabled]:focus > a:only-child,
.ant-btn-ghost[disabled]:hover > a:only-child,
.ant-btn-ghost[disabled] > a:only-child {
  color: currentColor;
}
.ant-btn-dashed:focus > a:only-child::after,
.ant-btn-dashed:hover > a:only-child::after,
.ant-btn-ghost[disabled]:active > a:only-child::after,
.ant-btn-ghost[disabled]:focus > a:only-child::after,
.ant-btn-ghost[disabled]:hover > a:only-child::after,
.ant-btn-ghost[disabled] > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn-dashed {
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  border-color: #d9d9d9;
  border-style: dashed;
}
.ant-btn-dashed:focus,
.ant-btn-dashed:hover {
  color: #40a9ff;
  background: #fff;
  border-color: #40a9ff;
}
.ant-btn-dashed:active {
  color: #096dd9;
  background: #fff;
  border-color: #096dd9;
}
.ant-btn-danger > a:only-child,
.ant-btn-dashed:active > a:only-child {
  color: currentColor;
}
.ant-btn-danger > a:only-child::after,
.ant-btn-dashed:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn-dashed[disabled],
.ant-btn-dashed[disabled]:active,
.ant-btn-dashed[disabled]:focus,
.ant-btn-dashed[disabled]:hover {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-danger:focus > a:only-child,
.ant-btn-danger:hover > a:only-child,
.ant-btn-dashed[disabled]:active > a:only-child,
.ant-btn-dashed[disabled]:focus > a:only-child,
.ant-btn-dashed[disabled]:hover > a:only-child,
.ant-btn-dashed[disabled] > a:only-child {
  color: currentColor;
}
.ant-btn-danger:focus > a:only-child::after,
.ant-btn-danger:hover > a:only-child::after,
.ant-btn-dashed[disabled]:active > a:only-child::after,
.ant-btn-dashed[disabled]:focus > a:only-child::after,
.ant-btn-dashed[disabled]:hover > a:only-child::after,
.ant-btn-dashed[disabled] > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn-danger {
  color: #fff;
  background: #ff4d4f;
  border-color: #ff4d4f;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-danger:focus,
.ant-btn-danger:hover {
  color: #fff;
  background: #ff7875;
  border-color: #ff7875;
}
.ant-btn-danger:active {
  color: #fff;
  background: #d9363e;
  border-color: #d9363e;
}
.ant-btn-danger:active > a:only-child,
.ant-btn-link > a:only-child {
  color: currentColor;
}
.ant-btn-danger:active > a:only-child::after,
.ant-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn-danger[disabled],
.ant-btn-danger[disabled]:active,
.ant-btn-danger[disabled]:focus,
.ant-btn-danger[disabled]:hover {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-danger[disabled]:active > a:only-child,
.ant-btn-danger[disabled]:focus > a:only-child,
.ant-btn-danger[disabled]:hover > a:only-child,
.ant-btn-danger[disabled] > a:only-child,
.ant-btn-link:focus > a:only-child,
.ant-btn-link:hover > a:only-child {
  color: currentColor;
}
.ant-btn-danger[disabled]:active > a:only-child::after,
.ant-btn-danger[disabled]:focus > a:only-child::after,
.ant-btn-danger[disabled]:hover > a:only-child::after,
.ant-btn-danger[disabled] > a:only-child::after,
.ant-btn-link:focus > a:only-child::after,
.ant-btn-link:hover > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn-link {
  color: #1890ff;
  background: 0 0;
  border-color: transparent;
  box-shadow: none;
}
.ant-btn-link:focus,
.ant-btn-link:hover {
  color: #40a9ff;
  background: 0 0;
}
.ant-btn-link:active {
  color: #096dd9;
  background: 0 0;
  border-color: #096dd9;
}
.ant-btn-link:active > a:only-child,
.ant-btn-text > a:only-child {
  color: currentColor;
}
.ant-btn-link:active > a:only-child::after,
.ant-btn-text > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn-link[disabled],
.ant-btn-link[disabled]:active,
.ant-btn-link[disabled]:focus,
.ant-btn-link[disabled]:hover {
  background: #f5f5f5;
}
.ant-btn-link[disabled]:active > a:only-child,
.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-link[disabled] > a:only-child {
  color: currentColor;
}
.ant-btn-link[disabled]:active > a:only-child::after,
.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-link[disabled] > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn-link:hover {
  background: 0 0;
}
.ant-btn-link:active,
.ant-btn-link:focus,
.ant-btn-link:hover {
  border-color: transparent;
}
.ant-btn-link[disabled],
.ant-btn-link[disabled]:active,
.ant-btn-link[disabled]:focus,
.ant-btn-link[disabled]:hover {
  color: rgba(0, 0, 0, 0.25);
  background: 0 0;
  border-color: transparent;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-text {
  color: rgba(0, 0, 0, 0.85);
  background: 0 0;
  border-color: transparent;
  box-shadow: none;
}
.ant-btn-text:focus,
.ant-btn-text:hover {
  color: #40a9ff;
  background: 0 0;
}
.ant-btn-text:focus > a:only-child,
.ant-btn-text:hover > a:only-child {
  color: currentColor;
}
.ant-btn-text:focus > a:only-child::after,
.ant-btn-text:hover > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn-text:active {
  color: #096dd9;
  background: 0 0;
}
.ant-btn-dangerous > a:only-child,
.ant-btn-text:active > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous > a:only-child::after,
.ant-btn-text:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn-text[disabled],
.ant-btn-text[disabled]:active,
.ant-btn-text[disabled]:focus,
.ant-btn-text[disabled]:hover {
  background: #f5f5f5;
}
.ant-btn-text[disabled]:active > a:only-child,
.ant-btn-text[disabled]:focus > a:only-child,
.ant-btn-text[disabled]:hover > a:only-child,
.ant-btn-text[disabled] > a:only-child {
  color: currentColor;
}
.ant-btn-text[disabled]:active > a:only-child::after,
.ant-btn-text[disabled]:focus > a:only-child::after,
.ant-btn-text[disabled]:hover > a:only-child::after,
.ant-btn-text[disabled] > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn-text:focus,
.ant-btn-text:hover {
  color: rgba(0, 0, 0, 0.85);
  background: rgba(0, 0, 0, 0.018);
  border-color: transparent;
}
.ant-btn-text:active {
  color: rgba(0, 0, 0, 0.85);
  background: rgba(0, 0, 0, 0.028);
  border-color: transparent;
}
.ant-btn-text[disabled],
.ant-btn-text[disabled]:active,
.ant-btn-text[disabled]:focus,
.ant-btn-text[disabled]:hover {
  color: rgba(0, 0, 0, 0.25);
  background: 0 0;
  border-color: transparent;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous {
  color: #ff4d4f;
  background: #fff;
  border-color: #ff4d4f;
}
.ant-btn-dangerous:focus,
.ant-btn-dangerous:hover {
  color: #ff7875;
  background: #fff;
  border-color: #ff7875;
}
.ant-btn-dangerous:focus > a:only-child,
.ant-btn-dangerous:hover > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous:focus > a:only-child::after,
.ant-btn-dangerous:hover > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn-dangerous:active {
  color: #d9363e;
  background: #fff;
  border-color: #d9363e;
}
.ant-btn-dangerous:active > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn-dangerous[disabled],
.ant-btn-dangerous[disabled]:active,
.ant-btn-dangerous[disabled]:focus,
.ant-btn-dangerous[disabled]:hover {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-primary:focus > a:only-child,
.ant-btn-dangerous.ant-btn-primary:hover > a:only-child,
.ant-btn-dangerous[disabled]:active > a:only-child,
.ant-btn-dangerous[disabled]:focus > a:only-child,
.ant-btn-dangerous[disabled]:hover > a:only-child,
.ant-btn-dangerous[disabled] > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-primary:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary:hover > a:only-child::after,
.ant-btn-dangerous[disabled]:active > a:only-child::after,
.ant-btn-dangerous[disabled]:focus > a:only-child::after,
.ant-btn-dangerous[disabled]:hover > a:only-child::after,
.ant-btn-dangerous[disabled] > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn-dangerous.ant-btn-primary {
  color: #fff;
  background: #ff4d4f;
  border-color: #ff4d4f;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-dangerous.ant-btn-link > a:only-child,
.ant-btn-dangerous.ant-btn-primary:active > a:only-child,
.ant-btn-dangerous.ant-btn-primary > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-link > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary:active > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn-dangerous.ant-btn-primary:focus,
.ant-btn-dangerous.ant-btn-primary:hover {
  color: #fff;
  background: #ff7875;
  border-color: #ff7875;
}
.ant-btn-dangerous.ant-btn-primary:active {
  color: #fff;
  background: #d9363e;
  border-color: #d9363e;
}
.ant-btn-dangerous.ant-btn-primary[disabled],
.ant-btn-dangerous.ant-btn-primary[disabled]:active,
.ant-btn-dangerous.ant-btn-primary[disabled]:focus,
.ant-btn-dangerous.ant-btn-primary[disabled]:hover {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-primary[disabled]:active > a:only-child,
.ant-btn-dangerous.ant-btn-primary[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-primary[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-primary[disabled] > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-primary[disabled]:active > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary[disabled] > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn-dangerous.ant-btn-link,
.ant-btn-dangerous.ant-btn-text {
  color: #ff4d4f;
  background: 0 0;
  border-color: transparent;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-link[disabled],
.ant-btn-dangerous.ant-btn-link[disabled]:active,
.ant-btn-dangerous.ant-btn-link[disabled]:focus,
.ant-btn-dangerous.ant-btn-link[disabled]:hover {
  background: #f5f5f5;
}
.ant-btn-dangerous.ant-btn-link:focus > a:only-child,
.ant-btn-dangerous.ant-btn-link:hover > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-link:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-link:hover > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn-dangerous.ant-btn-link:focus,
.ant-btn-dangerous.ant-btn-link:hover {
  color: #ff7875;
  background: 0 0;
  border-color: transparent;
}
.ant-btn-dangerous.ant-btn-link:active {
  color: #d9363e;
  background: 0 0;
  border-color: transparent;
}
.ant-btn-dangerous.ant-btn-link:active > a:only-child,
.ant-btn-dangerous.ant-btn-text > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-link:active > a:only-child::after,
.ant-btn-dangerous.ant-btn-text > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn-dangerous.ant-btn-link[disabled],
.ant-btn-dangerous.ant-btn-link[disabled]:active,
.ant-btn-dangerous.ant-btn-link[disabled]:focus,
.ant-btn-dangerous.ant-btn-link[disabled]:hover {
  color: rgba(0, 0, 0, 0.25);
  background: 0 0;
  border-color: transparent;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn-dangerous.ant-btn-text:focus,
.ant-btn-dangerous.ant-btn-text:hover {
  background: 0 0;
}
.ant-btn-dangerous.ant-btn-text:active {
  background: 0 0;
}
.ant-btn-dangerous.ant-btn-text[disabled],
.ant-btn-dangerous.ant-btn-text[disabled]:active,
.ant-btn-dangerous.ant-btn-text[disabled]:focus,
.ant-btn-dangerous.ant-btn-text[disabled]:hover {
  background: #f5f5f5;
}
.ant-btn-dangerous.ant-btn-text:focus > a:only-child,
.ant-btn-dangerous.ant-btn-text:hover > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-text:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-text:hover > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn-dangerous.ant-btn-text:focus,
.ant-btn-dangerous.ant-btn-text:hover {
  color: #ff7875;
  background: rgba(0, 0, 0, 0.018);
  border-color: transparent;
}
.ant-btn-dangerous.ant-btn-text:active {
  color: #d9363e;
  background: rgba(0, 0, 0, 0.028);
  border-color: transparent;
}
.ant-btn-background-ghost.ant-btn-primary > a:only-child,
.ant-btn-dangerous.ant-btn-text:active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-primary > a:only-child::after,
.ant-btn-dangerous.ant-btn-text:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn-dangerous.ant-btn-text[disabled],
.ant-btn-dangerous.ant-btn-text[disabled]:active,
.ant-btn-dangerous.ant-btn-text[disabled]:focus,
.ant-btn-dangerous.ant-btn-text[disabled]:hover {
  color: rgba(0, 0, 0, 0.25);
  background: 0 0;
  border-color: transparent;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-primary:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-primary:hover > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled] > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-primary:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled] > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn-icon-only {
  width: 32px;
  height: 32px;
  padding: 2.4px 0;
  font-size: 16px;
  border-radius: 4px;
  vertical-align: -1px;
}
.ant-btn-icon-only > * {
  font-size: 16px;
}
.ant-btn-icon-only.ant-btn-lg {
  width: 40px;
  height: 40px;
  padding: 4.9px 0;
  font-size: 18px;
  border-radius: 4px;
}
.ant-btn-icon-only.ant-btn-lg > * {
  font-size: 18px;
}
.ant-btn-icon-only.ant-btn-sm {
  width: 24px;
  height: 24px;
  padding: 0;
  font-size: 14px;
  border-radius: 4px;
}
.ant-btn-icon-only.ant-btn-sm > * {
  font-size: 14px;
}
.ant-btn-round {
  height: 32px;
  padding: 4px 16px;
  font-size: 14px;
  border-radius: 32px;
}
.ant-btn-round.ant-btn-lg {
  height: 40px;
  padding: 6.4px 20px;
  font-size: 16px;
  border-radius: 40px;
}
.ant-btn-round.ant-btn-sm {
  height: 24px;
  padding: 0 12px;
  font-size: 14px;
  border-radius: 24px;
}
.ant-btn-round.ant-btn-icon-only {
  width: auto;
}
.ant-btn-circle,
.ant-btn-circle-outline {
  min-width: 32px;
  padding-right: 0;
  padding-left: 0;
  text-align: center;
  border-radius: 50%;
}
.ant-btn-circle-outline.ant-btn-lg,
.ant-btn-circle.ant-btn-lg {
  min-width: 40px;
  border-radius: 50%;
}
.ant-btn-circle-outline.ant-btn-sm,
.ant-btn-circle.ant-btn-sm {
  min-width: 24px;
  border-radius: 50%;
}
.ant-btn::before {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  z-index: 1;
  display: none;
  background: #fff;
  border-radius: inherit;
  opacity: 0.35;
  transition: opacity 0.2s;
  content: "";
  pointer-events: none;
}
.ant-btn .anticon {
  transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-btn .anticon.anticon-minus > svg,
.ant-btn .anticon.anticon-plus > svg {
  shape-rendering: optimizeSpeed;
}
.ant-btn.ant-btn-loading {
  position: relative;
}
.ant-btn.ant-btn-loading:not([disabled]) {
  pointer-events: none;
}
.ant-btn > .ant-btn-loading-icon {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-btn > .ant-btn-loading-icon .anticon {
  padding-right: 8px;
}
.ant-btn-group {
  display: inline-flex;
}
.ant-btn-group,
.ant-btn-group > .ant-btn,
.ant-btn-group > span > .ant-btn {
  position: relative;
}
.ant-btn-group > .ant-btn:active,
.ant-btn-group > .ant-btn:focus,
.ant-btn-group > .ant-btn:hover,
.ant-btn-group > span > .ant-btn:active,
.ant-btn-group > span > .ant-btn:focus,
.ant-btn-group > span > .ant-btn:hover {
  z-index: 2;
}
.ant-btn-group > .ant-btn[disabled],
.ant-btn-group > span > .ant-btn[disabled] {
  z-index: 0;
}
.ant-btn-group .ant-btn-icon-only {
  font-size: 14px;
}
.ant-btn-group-lg > .ant-btn,
.ant-btn-group-lg > span > .ant-btn {
  height: 40px;
  padding: 6.4px 15px;
  font-size: 16px;
  border-radius: 0;
}
.ant-btn-group-lg .ant-btn.ant-btn-icon-only {
  width: 40px;
  height: 40px;
  padding-right: 0;
  padding-left: 0;
}
.ant-btn-group-sm > .ant-btn,
.ant-btn-group-sm > span > .ant-btn {
  height: 24px;
  padding: 0 7px;
  font-size: 14px;
  border-radius: 0;
}
.ant-btn-group-sm > .ant-btn > .anticon,
.ant-btn-group-sm > span > .ant-btn > .anticon,
.ant-form label {
  font-size: 14px;
}
.ant-btn-group-sm .ant-btn.ant-btn-icon-only {
  width: 24px;
  height: 24px;
  padding-right: 0;
  padding-left: 0;
}
.ant-btn + .ant-btn-group,
.ant-btn-group .ant-btn + .ant-btn,
.ant-btn-group .ant-btn + span,
.ant-btn-group span + .ant-btn,
.ant-btn-group + .ant-btn,
.ant-btn-group + .ant-btn-group,
.ant-btn-group > span + span {
  margin-left: -1px;
}
.ant-btn-group .ant-btn-primary + .ant-btn:not(.ant-btn-primary):not([disabled]) {
  border-left-color: transparent;
}
.ant-btn-group .ant-btn {
  border-radius: 0;
}
.ant-btn-group > .ant-btn:first-child,
.ant-btn-group > span:first-child > .ant-btn {
  margin-left: 0;
}
.ant-btn-group-sm > .ant-btn:only-child,
.ant-btn-group-sm > span:only-child > .ant-btn,
.ant-btn-group > .ant-btn:only-child,
.ant-btn-group > span:only-child > .ant-btn {
  border-radius: 4px;
}
.ant-btn-group > .ant-btn:first-child:not(:last-child),
.ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.ant-btn-group > .ant-btn:last-child:not(:first-child),
.ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
.ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
.ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.ant-btn-group > .ant-btn-group {
  float: left;
}
.ant-btn-group > .ant-btn-group:not(:first-child):not(:last-child) > .ant-btn {
  border-radius: 0;
}
.ant-btn-group > .ant-btn-group:first-child:not(:last-child) > .ant-btn:last-child {
  padding-right: 8px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-btn-group > .ant-btn-group:last-child:not(:first-child) > .ant-btn:first-child {
  padding-left: 8px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-btn-group-rtl.ant-btn + .ant-btn-group,
.ant-btn-group-rtl.ant-btn-group .ant-btn + .ant-btn,
.ant-btn-group-rtl.ant-btn-group .ant-btn + span,
.ant-btn-group-rtl.ant-btn-group span + .ant-btn,
.ant-btn-group-rtl.ant-btn-group + .ant-btn,
.ant-btn-group-rtl.ant-btn-group + .ant-btn-group,
.ant-btn-group-rtl.ant-btn-group > span + span,
.ant-btn-rtl.ant-btn + .ant-btn-group,
.ant-btn-rtl.ant-btn-group .ant-btn + .ant-btn,
.ant-btn-rtl.ant-btn-group .ant-btn + span,
.ant-btn-rtl.ant-btn-group span + .ant-btn,
.ant-btn-rtl.ant-btn-group + .ant-btn,
.ant-btn-rtl.ant-btn-group + .ant-btn-group,
.ant-btn-rtl.ant-btn-group > span + span {
  margin-right: -1px;
  margin-left: auto;
}
.ant-btn-group.ant-btn-group-rtl {
  direction: rtl;
}
.ant-btn-group-rtl.ant-btn-group > .ant-btn:first-child:not(:last-child),
.ant-btn-group-rtl.ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 0;
}
.ant-btn-group-rtl.ant-btn-group > .ant-btn:last-child:not(:first-child),
.ant-btn-group-rtl.ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
  border-top-left-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 4px;
}
.ant-btn-group-rtl.ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
.ant-btn-group-rtl.ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 0;
}
.ant-btn-group-rtl.ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
.ant-btn-group-rtl.ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
  border-top-left-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 4px;
}
.ant-btn:active > span,
.ant-btn:focus > span {
  position: relative;
}
.ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  margin-left: 8px;
}
.ant-btn-background-ghost {
  color: #fff;
  background: 0 0 !important;
  border-color: #fff;
}
.ant-btn-background-ghost.ant-btn-primary {
  color: #1890ff;
  background: 0 0;
  border-color: #1890ff;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-primary:focus,
.ant-btn-background-ghost.ant-btn-primary:hover {
  color: #40a9ff;
  background: 0 0;
  border-color: #40a9ff;
}
.ant-btn-background-ghost.ant-btn-primary:active {
  color: #096dd9;
  background: 0 0;
  border-color: #096dd9;
}
.ant-btn-background-ghost.ant-btn-danger > a:only-child,
.ant-btn-background-ghost.ant-btn-primary:active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-danger > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn-background-ghost.ant-btn-primary[disabled],
.ant-btn-background-ghost.ant-btn-primary[disabled]:active,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-danger:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-danger:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-danger:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn-background-ghost.ant-btn-danger,
.ant-btn-background-ghost.ant-btn-dangerous {
  color: #ff4d4f;
  background: 0 0;
  border-color: #ff4d4f;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-danger:focus,
.ant-btn-background-ghost.ant-btn-danger:hover {
  color: #ff7875;
  background: 0 0;
  border-color: #ff7875;
}
.ant-btn-background-ghost.ant-btn-danger:active {
  color: #d9363e;
  background: 0 0;
  border-color: #d9363e;
}
.ant-btn-background-ghost.ant-btn-danger:active > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-danger:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn-background-ghost.ant-btn-danger[disabled],
.ant-btn-background-ghost.ant-btn-danger[disabled]:active,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous:hover > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous:hover > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn-background-ghost.ant-btn-dangerous:focus,
.ant-btn-background-ghost.ant-btn-dangerous:hover {
  color: #ff7875;
  background: 0 0;
  border-color: #ff7875;
}
.ant-btn-background-ghost.ant-btn-dangerous:active {
  color: #d9363e;
  background: 0 0;
  border-color: #d9363e;
}
.ant-btn-background-ghost.ant-btn-dangerous:active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-dangerous:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn-background-ghost.ant-btn-dangerous[disabled],
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:active,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:active > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous[disabled] > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous[disabled] > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link {
  color: #ff4d4f;
  background: 0 0;
  border-color: transparent;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover {
  color: #ff7875;
  background: 0 0;
  border-color: transparent;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active {
  color: #d9363e;
  background: 0 0;
  border-color: transparent;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled],
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  content: "";
}
.ant-btn-two-chinese-chars::first-letter {
  letter-spacing: 0.34em;
}
.ant-btn-two-chinese-chars > :not(.anticon) {
  margin-right: -0.34em;
  letter-spacing: 0.34em;
}
.ant-btn-block {
  width: 100%;
}
.ant-btn:empty {
  display: inline-block;
  width: 0;
  visibility: hidden;
  content: "\a0";
}
a.ant-btn {
  padding-top: 0.1px;
  line-height: 30px;
}
a.ant-btn-lg {
  line-height: 38px;
}
a.ant-btn-sm {
  line-height: 22px;
}
.ant-btn-rtl {
  direction: rtl;
}
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary + .ant-btn-primary,
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child) {
  border-right-color: #40a9ff;
  border-left-color: #d9d9d9;
}
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled],
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled] {
  border-right-color: #d9d9d9;
  border-left-color: #40a9ff;
}
.ant-layout {
  display: flex;
  flex: auto;
  flex-direction: column;
  min-height: 0;
  background: #ffffff;
}
.ant-layout,
.ant-layout * {
  box-sizing: border-box;
}
.ant-layout.ant-layout-has-sider {
  flex-direction: row;
}
.ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout-content {
  overflow-x: hidden;
}
.ant-layout-footer,
.ant-layout-header {
  flex: 0 0 auto;
}
.ant-layout-header {
  height: 64px;
  padding: 0 50px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 64px;
  background: #ffffff;
}
.ant-layout-footer {
  padding: 24px 50px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  background: #ffffff;
}
.site-layout{
  padding-left: 0!important;
  padding-right: 0!important;
}
.ant-layout-content {
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 0;
  padding: 3%;
  margin-left: 0!important;
  margin-right: 0!important;
}
.ant-layout-sider {
  position: relative;
  min-width: 0;
  transition: all 0.2s;
}
.ant-layout-sider-children {
  height: 100%;
  margin-top: -0.1px;
  padding-top: 0.1px;
}
.ant-layout-sider-has-trigger {
  padding-bottom: 48px;
}
.ant-layout-sider-right {
  order: 1;
}
.ant-layout-sider-trigger {
  position: fixed;
  bottom: 0;
  z-index: 1;
  height: 48px;
  color: #fff;
  line-height: 48px;
  text-align: center;
  background: #002140;
  cursor: pointer;
  transition: all 0.2s;
}
.ant-layout-sider-zero-width > * {
  overflow: hidden;
}
.ant-layout-sider-zero-width-trigger {
  position: absolute;
  top: 64px;
  right: -36px;
  z-index: 1;
  width: 36px;
  height: 42px;
  color: #fff;
  font-size: 18px;
  line-height: 42px;
  text-align: center;
  background: #ffffff;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  transition: background 0.3s ease;
}
.ant-layout-sider-zero-width-trigger::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  transition: all 0.3s;
  content: "";
}
.ant-layout-sider-zero-width-trigger:hover::after {
  background: rgba(255, 255, 255, 0.1);
}
.ant-layout-sider-zero-width-trigger-right {
  left: -36px;
  border-radius: 4px 0 0 4px;
}
.ant-layout-sider-light {
  background: rgba(255, 255, 255, 0.0);
}
.ant-layout-sider-light .ant-layout-sider-trigger,
.ant-layout-sider-light .ant-layout-sider-zero-width-trigger {
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
}
.ant-layout-rtl {
  direction: rtl;
}
.ant-layout-header .ant-menu {
  line-height: inherit;
}

